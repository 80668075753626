import { useTheme } from '@emotion/react';
import { Picture } from '../picture/picture';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { Link } from '../styled/Link';
import { City, Compilation } from '../../models';
import { ImageSources } from '../../models/image';

export function CompilationCard({
  item,
  currentCity,
}: {
  item: Compilation;
  currentCity: City;
}) {
  const theme = useTheme();
  const getMainImage = () => {
    if (!item) {
      return null;
    }
    const { images } = item;
    if (!Array.isArray(images)) {
      return null;
    }
    const mainImage = images.find((img) => img.is_main);
    if (mainImage) {
      return mainImage;
    }
    return null;
  };

  const makeHref = (compilationData: Compilation) => {
    if (compilationData.cities.length > 1) {
      return ['', 'compilations', item.slug, ''].join('/');
    }
    return ['', currentCity.url, 'compilations', item.slug, ''].join('/');
  };

  return (
    <Link
      href={makeHref(item)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        position: 'relative',
        width: '100%',
        borderRadius: '4px',
        textDecoration: 'none',
        color: '#fff',
        display: 'block',
        backgroundSize: 'cover',
        overflow: 'hidden',
        backgroundPosition: '50%',
        backgroundColor: '#ddd',
        transition: 'all 0.3s ease',
        '&:hover': {
          color: theme.colors.textWhite,
          '&::before': {
            opacity: 1,
          },
          [DEVICE_SCREENS_MAX_STRING.md]: {
            transform: 'none',
            boxShadow: 'none',
          },
        },
        '&::before': {
          opacity: 0,
          background:
            'linear-gradient(180deg, rgba(19, 19, 19, 0.25) 0%, rgba(19, 19, 19, 0.75) 100%)',
          transition: 'all 0.3s ease',
          content: '""',
          display: 'block',
          paddingTop: '100%',
        },
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Picture
          src={getMainImage() as ImageSources}
          alt={item.title}
          loading='lazy'
          is360
        />
      </div>
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          backgroundImage:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.6) 80%)',
        }}
      >
        <div
          css={{
            transition: 'all 0.3s ease',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            wordBreak: 'break-word',
            position: 'absolute',
            top: '50%',
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            fontSize: '18px',
            fontFamily: 'Golos',
            fontWeight: 700,
            lineHeight: 1.25,
            textShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
            padding: '50px 20px',
            '&:hover': {
              fontFamily: 'Montserrat',
              top: '0%',
            },
            [DEVICE_SCREENS_MAX_STRING.lg]: {
              fontSize: 16,
              padding: '0 10px',
              fontWeight: 700,
            },

            [DEVICE_SCREENS_MAX_STRING.md]: {
              fontSize: 13,
              lineHeight: '1.15',
              textAlign: 'left',
              fontWeight: 400,
              padding: '0 4px 6px 10px',
            },
            [DEVICE_SCREENS_MAX_STRING.sm]: {
              minHeight: 'auto',
              padding: '0 20px 10px 15px',
              textAlign: 'left',
              fontSize: '.85em',
              fontWeight: 400,
              justifyContent: 'flex-start',
            },
          }}
        >
          {item.title}
        </div>
      </div>
    </Link>
  );
}
